<template>
  <div>
    <!-- 有计划运单 -->
    <nav-bar :nav-info='navInfo' @screen="screen"></nav-bar>
       <!-- 筛选弹出框 -->
    <van-popup v-model="showScreen" closeable round position="bottom" >
     <div class="screen_title">筛选</div>   
    <h4>派发时间</h4>
    <div class="screen_time">
      <div>
        <el-date-picker type="date" placeholder="开始日期" value-format="yyyy-MM-dd" v-model="screenForm.createTimeStart" style="width: 90%;"></el-date-picker>
      </div>
      <div>
        <el-date-picker type="date" placeholder="结束日期" value-format="yyyy-MM-dd" v-model="screenForm.createTimeEnd" :picker-options="endTime" style="width: 90%;"></el-date-picker>
      </div>
    </div>
     <template  v-if="currentTabIndex==1">
      <h4>司机姓名</h4>
      <div class="screen_orderNo">
        <van-field v-model="screenForm.driverName" clearable  placeholder="请输入司机姓名" />
      </div>
      <h4>订单号</h4>
      <div class="screen_orderNo">
        <van-field v-model="screenForm.orderNo" clearable  placeholder="请输入订单号" />
      </div>
      <h4>原始订单号</h4>
      <div class="screen_ys_orderNo">
        <van-field v-model="screenForm.receiveOrderNo" clearable placeholder="请输入原始订单号" />
      </div>
    </template>
    <template >
      <h4>计划号</h4>
      <div class="screen_ys_orderNo">
        <van-field v-model="screenForm.transportNo" clearable placeholder="请输入计划号" />
      </div>
    </template>
  
     <div class="screen_sub">
      <div @click="onReset">重置</div>
      <div @click="screenBtn">确定</div>
    </div>
    </van-popup>
    <!-- 搜索框 和tabbar-->
    <div class="searchBox">
      <div class="tab_left " :class="[currentTabIndex==0?'active':'']" @click="changeTab(0)">计划</div>
      <div class="tab_rigth " :class="[currentTabIndex==1?'active':'']" @click="changeTab(1)">运力</div>
      <div class="tab_search">
        <form action="javascript:return ture">
          <van-search v-model="searchValue" shape="round" @search="onSearch"   placeholder="请输入计划编号/订单号搜索"/>
        </form>
      </div>
    </div>
    <!--搜索框 和tabbar结束  -->
    <!-- 点击运力显示 -->
    <div v-if="currentTabIndex==1">
      <jk-list  :screenForm="screenForm" ref="childList" ></jk-list>
    </div>
    
    <!-- //点击计划显示 -->
    <div v-else-if="currentTabIndex == 0" >
      <car-list ref="carList" :screenForm="screenForm"></car-list>
    </div>
  </div>
</template>
<script>
import NavBar from '../../components/NavBar/NavBar.vue'
import JkList from '../../components/Order/JkList.vue'
import CarList from '../../components/Order/CarList.vue'
export default {
  components: {
    NavBar,
    JkList,
    CarList
  },
  data() {
    return {
      navInfo: {
        title:'运力监控',
        left:true,
        rigth:true,
        path:'/home'
      },
      searchValue:'',
      showScreen:false,
        //筛选条件
      screenForm: {
        transportNo:'',
        createTimeStart:'',
        createTimeEnd:'',
        driverName:'',//司机姓名
        orderNo:'',//订单号
        receiveOrderNo:'',//原始订单号

      },
      orderList:[
        {orderNo:'0001245145454545454',time:'2021/8/17 09:19:30'},
        {orderNo:'0001245145454545454',time:'2021/8/17 09:18:30'},
        {orderNo:'0001245145454545454',time:'2021/8/17 09:10:30'},
        {orderNo:'0001245145454545454',time:'2021/8/17 09:11:30'},
        {orderNo:'0001245145454545454',time:'2021/8/17 09:12:30'},
      ],
      currentTabIndex:0,//默认显示的tab
      showAll:false,//是否显示更多     
    }
  },
  methods: {
    //搜索框搜索事件
    onSearch(value) {
       if (this.currentTabIndex == 1) {
        //如果currTabIndex ==0 搜索跳到常规详情框
        this.$router.push({
          path:`/regularWaybillSearch`,
          query: {
            orderNo:value,
            type:'plan'
          }
        }) 
      } else {
        //调用子组件方法 运力列表的方法
         this.$router.push({
          path:`/planWaybillSearch`,
          query: {
            transportNo:value,
          }
        })
      }
    },
    //触发筛选框弹窗显示
    screen(value) {
      this.showScreen = value
    },
    //触发筛选框搜索
    screenBtn() {
      if (this.currentTabIndex == 0) {
        this.$refs.carList.getList(0)
      } else if (this.currentTabIndex == 1) {
        this.$refs.childList.getList(0)
      }
      this.showScreen = false
    },
    //清除筛选条件
    onReset() {
      for (const key in this.screenForm) {
        this.screenForm[key] = ''
      }
    },
    changeTab(index) {
      this.currentTabIndex = index
      // if (this.currentTabIndex == 0) {
        // this.$refs.carList.getList(0)
      // } else {
        //调用子组件方法 运力列表的方法
        // this.$refs.childList.getList(0)
      // }
    }
  },
  computed: {
    //大于开始时间,判断时间限制
    endTime() {
      return {
          disabledDate: time => {
          return time.getTime() < new Date(this.screenForm.createTimeStart).getTime()
        }
      }
    }
  },
   
}
</script>
<style lang="scss"  scoped>

// 搜索框和tabbar
.searchBox {
  height: 1.55rem;
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  .tab_left {
    font-size: 0.37rem;
    // width: 1.5rem;
    text-align: center;
    padding: 0 0.1rem;
    margin-left: 0.5rem;
    color: #999999;
  }
  .tab_rigth {
    font-size: 0.37rem;
    // width: 1.5rem;
    text-align: center;
    padding: 0 0.1rem;
    margin-left: 0.5rem;
    color: #999999;
  }
  .active {
    color: #000000;
    font-size: 0.43rem;
    border-bottom: 0.1rem solid #FB6600;
  }
  .tab_search {
    flex: 1;
  }
  .van-search {
    background: #F5F5F5;
  }
  .van-cell {
    background: #FFFFFF;
  
  }
}
// 搜索框和tabbar结束
.fenge {
   height: 0.03rem;
   margin-top: 0.27rem;
   background: #F5F5F5;        
}







// screen弹出框
.screen_title {
  font-size: 0.43rem;
  text-align: center;
  line-height: 0.59rem;
  padding-top: 0.27rem;
}
h4 {
  font-size: 0.37rem;
  font-weight: 400;
  line-height: 0.53rem;
  padding: 0.27rem 0 0.17rem 0.27rem;
}
h3 {
  font-size: 0.37rem;
}
.screen_type {
  height: 0.8rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .screen_type_item {
    margin: 0.3rem;
    width: 2.35rem;
    height: 100%;
    background: #F5F5F5;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    color: #999999;
  }
  .screen_type_check {
    color: #FB6600;
    background: rgba($color: #FB6600, $alpha: 0.1);
  }
}
.screen_orderNo,.screen_ys_orderNo,.screen_ys_orderNo {
  width: 9.15rem;
  height: 0.8rem;
  border: #FB6600;
  margin: 0 auto;
}
.van-cell {
  background: #F5F5F5;
  border-radius: 0.8rem;
  padding: 0.15rem 0.4rem;
}
.screen_sub {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.45rem;
  margin-bottom: 0.35rem;
  div {
    height: 100%;
    width: 2.13rem;
    border-radius: 0.85rem;
    text-align: center;
    color: #FFFFFF;
    font-size: 0.43rem;
    line-height: 0.85rem;
    background: #FB6600;
    margin: 0 30px;
  }
  div:nth-child(1) {
    background: #cccccc;
  }
}
.screen_time {
  width: 9.15rem;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 4.21rem;
    /deep/.el-input__inner {
      border-radius: 0.8rem;
      height: 0.8rem;
       background: #F5F5F5;
       border: 0;
    }
    /deep/.el-icon-date {
      opacity: 0;
    }
    /deep/.el-icon-circle-close {
      line-height: 0.8rem;
    }
  }
}
//screen弹出框结束

/deep/.van-popup {
  max-height: 85%;
  z-index: 1000!important;
}
/deep/.el-picker-panel .el-date-picker .el-popper {
  z-index: 2999!important;
}
/deep/.van-overlay {
  z-index: 889!important;
}

</style>