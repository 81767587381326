<template>
  <!-- 计划和运单列表 -->
  <div
    class="order-list"
    v-infinite-scroll="getList"
    infinite-scroll-delay="500"
    infinite-scroll-disabled="Loading"
    infinite-scroll-distance="50"
  >
    <div v-if="noPlanOrder">
      <div v-for="(item, index) in dataList" :key="index">
        <div class="orderNo">
          <div class="orderNo_img">
            <img :src="require('@/assets/img/icon/cg_order2x.png')" /><img />
          </div>
          <div class="orderNo_no">计划编号：{{ item.transportNo }}</div>
        </div>
        <div class="order_main">
          <div class="order_time">
            <span>{{ item.createTime | formatDate("YYYY/MM/DD HH:mm") }}</span>
            <span @click="godetails(item)">详情</span>
          </div>
          <div class="fenge"></div>
          <div class="order_info">
            <div class="order_info_item">
              <span>发货日期：{{ item.endTime }}</span>
            </div>
            <div class="order_info_item">
              <span>出发城市：{{ item.sCity }}</span>
              <span>目的城市：{{ item.eCity }}</span>
            </div>
            <div class="order_info_item">
              <span>结算类型：{{ item.settlementsType }}</span>
              <span
                >计划：{{ item.totalPlan || 0 }}
                <i v-if="item.settlementsType == '整车'">米</i>
                <i v-else-if="item.settlementsType == '零担'">立方</i>
              </span>
            </div>
            <div class="order_info_item">
              <span>服务产品：{{ item.serviceType }}</span>
            </div>
            <div class="order_info_item">
              <span>卸货点信息：{{ item.unloadingPoint }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fenge"></div>
    </div>
    <div v-else>
      <van-empty
        class="custom-image"
        image="https://img01.yzcdn.cn/vant/empty-image-default.png"
        description="没有找到订单信息"
      />
    </div>
    <div class="noLoading" v-if="noPlanOrder">
      <span v-if="Loading">已经到底了...</span>
      <span v-else>正在加载...</span>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  props: {
    screenForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      count: 8,
      Loading: false,
      query: {
        size: 10,
        page: 0,
      },
      dataList: [],
      noPlanOrder: true,
    };
  },
  created() {
    // console.log("screenForm",this.screenForm);
  },
  methods: {
    //计算客户整车数量
    sum(a, b, c, d) {
      let arr = [];
      let sum = 0;
      arr.push(a, b, c, d);
      for (let i = 0; i < arr.length; i++) {
        sum += Number(arr[i]);
      }
      return sum;
    },
    //获取列表
    async getList(type, value) {
      let transportNo = null;
      //如果有type则是筛选框搜索，把page和页面数据列表重置
      if (type == 0 || value) {
        console.log(111);
        this.query.page = 0;
        transportNo = value;
        this.dataList = [];
        this.Loading = false;
      } else if (this.screenForm.transportNo.length > 0) {
        this.query.page = 0;
        this.dataList = [];
        transportNo = this.screenForm.transportNo;
      } else {
        transportNo = this.$route.query.transportNo;
      }
      this.query.page++;
      //入参
      let params = {
        userId: this.$store.state.userInfo.deptId,
        page: this.query.page,
        size: this.query.size,
        transportNo: transportNo,
        createTimeStart: this.screenForm.createTimeStart,
        createTimeEnd: this.screenForm.createTimeEnd,
      };
      let res = await this.$Api.planOrderList(params);
      if (res.data.data[0].list.length == 0) {
        this.noPlanOrder = false;
      } else {
        this.noPlanOrder = true;
      }
      let data = res.data.data[0].list;
      //当前请求数据小于入参请求数则不再下拉加载
      if (data.length < this.query.size) {
        this.Loading = true;
      }
      this.dataList = this.dataList.concat(data);
    },
    //查看获取计划详情
    godetails(item) {
      this.$router.push({
        path: "/planDetails",
        query: {
          transportNo: item.transportNo, //运单号传到详情页
          transportNoId: item.id, //运单ID传到详情页
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
i {
  font-style: normal;
}
.order-list {
  background: #f5f5f5;
  height: 84vh;
  font-size: 0.37rem;
  overflow: scroll;
}
.orderNo {
  padding-top: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .orderNo_no {
    font-size: 0.37rem;
    color: #999999;
    flex: 1;
  }
  .orderNo_img {
    margin-left: 0.43rem;
    width: 0.64rem;
    height: 0.64rem;
    img {
      height: 100%;
    }
  }
}
.order_main {
  border-radius: 0.3rem;
  background: #ffffff;
  font-size: 0.37rem;
  padding-bottom: 0.15rem;
  .order_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      flex: 1;
      padding: 0.2rem 0.43rem;
    }
    span:nth-child(2) {
      text-align: right;
      color: #1aa3ff;
    }
  }
}
.order_info {
  padding-left: 0.75rem;
  .order_info_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      padding: 0.06rem 0;
    }
    span:nth-child(2) {
      width: 3.5rem !important;
    }
  }
}
.fenge {
  height: 0.03rem;
  background: #f5f5f5;
  margin-bottom: 0.1rem;
}
.noLoading {
  text-align: center;
  height: 0.5rem;
  font-size: 0.37rem;
  color: #999999;
}
</style>