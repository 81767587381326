<template>
  <div>
    <!-- 常规运单 -->
    <div
      class="main"
      v-infinite-scroll="getList"
      infinite-scroll-delay="500"
      infinite-scroll-disabled="scorllLoading"
      infinite-scroll-distance="50"
    >
      <div v-if="!dataList.code">
        <div
          class="deriver_main"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <!-- 司机信息 -->
          <div class="driverInfo" v-if="item.driverInfo">
            <div class="driverInfo_detail">
              <p class="driver_name">
                {{ item.driverInfo.driverName }}
                <span
                  class="useYd"
                  v-if="item.orderInfo.length || item.planOrderInfo.length > 0"
                  >有运单</span
                >
                <span class="noYd" v-else>无运单</span>
              </p>
            </div>
            <div class="driverInfo_detail">
              <span>车牌号：{{ item.driverInfo.carNo }}</span>
              <span>电话：{{ item.driverInfo.phone }}</span>
            </div>
          </div>
          <div
            class="fenge1"
            v-if="item.orderInfo.length > 0 || item.planOrderInfo.length > 0"
          ></div>
          <!--订单信息  -->
          <div
            class="orderInfo"
            v-if="item.orderInfo && item.orderInfo.length > 0"
          >
            <div
              class="mark_ddd"
              v-if="item.orderInfoStatu == false"
              @click.stop="ChangeOrderInfoStatu(true, item)"
            ></div>
            <!-- 折叠按钮 -->
            <div
              class="mark_zk"
              v-else-if="item.orderInfoStatu == true"
              @click.stop="ChangeOrderInfoStatu(false, item)"
            ></div>
            <div v-if="item.orderInfo && item.orderInfo.length > 0">
              <h4>运单信息 <span @click="showWayBill(item)">详情></span></h4>
              <div class="orderListBox">
                <div
                  class="orderList1"
                  v-for="(infoItem, infoIndex) in showList(item)"
                  :key="infoIndex"
                  @click="goWaybillDetails(infoItem.orderNo)"
                >
                  <span>{{ infoItem.orderNo }}</span>
                  <span class="orderList_time"
                    ><van-image
                      :src="require('@/assets/img/icon/waybill_time2x.png')"
                    ></van-image>
                    {{
                      infoItem.createTime | formatDate("YYYY/MM/DD HH:mm:ss")
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!--计划信息  -->
          <div class="orderInfo">
            <div
              class="mark_ddd"
              v-if="item.planPrderInfoStatu == false"
              @click.stop="ChangeOrderInfoJhStatu(true, item)"
            ></div>
            <!-- 折叠按钮 -->
            <div
              class="mark_zk"
              v-else-if="item.planPrderInfoStatu == true"
              @click.stop="ChangeOrderInfoJhStatu(false, item)"
            ></div>
            <div v-if="item.planOrderInfo && item.planOrderInfo.length > 0">
              <h4>计划信息<span @click="showWayBillJh(item)">详情></span></h4>
              <div class="orderListBox">
                <div
                  class="orderList"
                  v-for="(planInfoItem, planInfoIndex) in showListPlan(item)"
                  :key="planInfoIndex + 1"
                >
                  <span>{{ planInfoItem.transportNo }}</span>
                  <span class="orderList_time"
                    ><van-image
                      :src="require('@/assets/img/icon/waybill_time2x.png')"
                    ></van-image
                    >{{
                      planInfoItem.createTime
                        | formatDate("YYYY/MM/DD HH:mm:ss")
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/empty-image-default.png"
          description="没有找到订单信息"
        />
      </div>
      <!-- 弹出层常规订单详情 -->
      <van-popup v-model="showPopwaybill" closeable round position="bottom">
        <div class="detail_title">运单详情</div>
        <div class="orderBox">
          <div class="order" v-for="(item, index) in orderDetails" :key="index">
            <div class="order_tile">
              <div class="order_tile_container">
                <div class="order_title_img1">
                  <van-image
                    :src="require('@/assets/img/icon/cg_order2x.png')"
                  ></van-image>
                </div>
                <div class="order_no">订单号：{{ item.orderNo }}</div>
                <div class="order_time">
                  {{ item.createTime | formatDate("YYYY/MM/DD HH:mm") }}
                </div>
              </div>
            </div>
            <div class="order_container">
              <div class="fenge1"></div>
              <div class="order_userinfo">
                <!-- check选择框 -->
                <div class="order_userinfo_check">
                  <div class="checked">
                    <van-checkbox-group
                      v-model="checkedResult"
                      ref="checkboxGroup"
                    >
                      <van-checkbox
                        :name="item"
                        checked-color="#FD5C0C"
                      ></van-checkbox>
                    </van-checkbox-group>
                  </div>
                </div>
                <!-- 右边订单信息 -->
                <div class="order_userinfo_rigth">
                  <div class="order_userinfo_rigth1">
                    <div class="order_userinfo_rigth_item">
                      <span>收货人：{{ item.receiverName }}</span>
                    </div>
                    <div class="order_userinfo_rigth_item">
                      <span class="tel_icon">{{ item.phone }}</span>
                    </div>
                  </div>
                  <!-- 收货地址 -->
                  <div class="order_userinfo_rigth_adress">
                    <div class="adress_text">{{ item.address }}</div>
                  </div>
                  <!-- 参数 -->
                  <div class="order_userinfo_rigth1 order_params">
                    <span>体积：{{ item.cargoSize | toFixed(2) }}m³</span>
                    <span>重量：{{ item.cargoWeight | toFixed(2) }}t</span>
                    <span>数量：{{ item.cargoNum | toFixed(2) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 弹出层按钮 -->
        <div class="footer">
          <van-uploader :after-read="afterRead" :before-read="beforeRead">
            <!-- <div class="btn_qx" @click="UploadReceipt">上传回执单</div> -->
            <div class="btn_qx">上传回执单</div>
          </van-uploader>
          <div class="btn_sub" @click="CloseOrder">结束订单</div>
        </div>
      </van-popup>
      <!-- 弹出层计划订单详情 -->
      <van-popup v-model="showPopwaybillJh" closeable round position="bottom">
        <div class="detail_title">计划详情</div>
        <div class="orderBox">
          <!-- <car-list ></car-list> -->
          <div v-for="(item, index) in planOrderDetails" :key="index">
            <div class="orderNo">
              <div class="orderNo_img">
                <img
                  :src="require('@/assets/img/icon/cg_order2x.png')"
                /><img />
              </div>
              <div class="orderNo_no">计划编号：{{ item.transportNo }}</div>
            </div>
            <div class="order_main">
              <div class="order_time">
                <span>{{
                  item.createTime | formatDate("YYYY/MM/DD HH:mm")
                }}</span>
                <span @click="godetails(item)">详情</span>
              </div>
              <div class="fenge1"></div>
              <div class="order_info">
                <div class="order_info_item">
                  <span>发货日期：{{ item.endTime }}</span>
                </div>
                <div class="order_info_item">
                  <span>出发城市：{{ item.sCity }}</span>
                  <span>目的城市：{{ item.eCity }}</span>
                </div>
                <div class="order_info_item">
                  <span>结算类型：{{ item.settlementsType }}</span>
                  <span>计划：{{ item.totalPlan }}</span>
                </div>
                <div class="order_info_item">
                  <span>服务产品：{{ item.serviceType }}</span>
                </div>
                <div class="order_info_item">
                  <span>卸货点信息：{{ item.unloadingPoint }}</span>
                </div>
              </div>
            </div>
            <div class="fenge"></div>
          </div>
        </div>
      </van-popup>
      <div class="noLoading">
        <span v-if="scorllLoading">已经到底了...</span>
        <span v-else>正在加载...</span>
      </div>
    </div>
  </div>
</template>
<script>
import CarList from "@/components/Order/CarList.vue";
//压缩图片
import lrz from "lrz";
import { Toast, Dialog } from "vant";
import Quagga from "quagga";
import QrCode from "qrcode-decoder"; //识别条形码
export default {
  components: {
    CarList,
  },
  props: {
    screenForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      scorllLoading: false,
      showPopwaybill: false,
      showPopwaybillJh: false,
      checkedResult: [],
      showScreen: false,
      orderDetails: [],//弹出的运单详情订单列表
      planOrderDetails: [],//弹出的计划详情列表
      showAll: false, //是否显示更多
      query: {
        page: 0,
        size: 10,
      },
      dataList: [],
      flag: false,
    };
  },
  mounted() {},
  methods: {
    //点击计划的一项跳转到计划详情页
    godetails(item) {
      this.showPopwaybillJh = false;
      this.$router.push({
        path: "/planDetails",
        query: {
          transportNo: item.transportNo,
          transportNoId: item.id,
        },
      });
    },
    //显示常规运单详情弹窗
    showWayBill(item) {
      if (item.orderInfo.length != 0) {
        this.showPopwaybill = true;
        this.orderDetails = item.orderInfo;
        this.checkedResult = [];
      } else {
        Toast("暂无订单信息");
      }
    },
    //显示计划运单详情弹窗
    showWayBillJh(item) {
      if (item.planOrderInfo.length != 0) {
        this.showPopwaybillJh = true;
        this.planOrderDetails = item.planOrderInfo;
        // this.checkedResult=[]
      } else {
        Toast("暂无计划信息");
      }
    },
    //上传之前验证
    beforeRead(file) {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        Toast("请上传 jpg/png 格式图片");
        return false;
      } else if (file.size > 5242880) {
        Toast("请上传小于5MB的图片");
        return false;
      }
      return true;
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      // 参数dataurl base64字符串
      // 参数filename 图片名称
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    //上传回执单图片
    async afterRead(files) {
      if (this.checkedResult.length <= 0) {
        Toast("请先选择订单");
      } else if (this.checkedResult.length > 1) {
        Toast("回执单不能批量进行操作哦");
      } else {
        this.getUrl(files.file);
      }
    },

    //识别二维码和条形码
    async getUrl(file) {
      let _this = this;
      _this.flag = false;
      let url = _this.getObjectURL(file);
      let config = Object.assign({}, _this.state, { src: url });
      let qr = new QrCode();
      let url2 = _this.getObjectURL(file);
      if (!_this.flag) {
        Quagga.decodeSingle(
          config,
          await function (result) {
            //识别结果 如果有result.codeResult，证明识别成功条形码
            if (result && result.codeResult) {
              _this.flag = true;
              _this.uploadImg(file);
            } else {
              // console.log("未识别到图片中的二维码！");
              qr.decodeFromImage(url2).then((res) => {
                //打印结果为 解析出来的 二维码地址
                // console.log(res);
                if (res) {
                  _this.flag = true;
                  _this.uploadImg(file);
                } else {
                  _this.flag = false;
                  // console.log("未识别到图片的条形码");
                  Dialog.confirm({
                    message: "未识别到您的二维码，是否上传",
                  })
                    .then(() => {
                      Toast("确认上传");
                      _this.uploadImg(file);
                    })
                    .catch(() => {
                      Toast("取消上传");
                    });
                }
              });
            }
          }
        );
      }
    },
    async uploadImg(file) {
      let param = new FormData();
      if (file.size > 1048576) {
        //根据图片字节去判断是否压缩图片 1M
        lrz(file, { quality: 0.5 }).then(async (rst) => {
          // console.log(rst);
          let base64 = this.dataURLtoFile(rst.base64, file.name);
          param.append("file", base64);
          let res = await this.$Api.uploadPicture(
            this.checkedResult[0].phone,
            param
          );
          if (res.data.cnt == 1) {
            Toast.success("上传回执单成功");
          } else {
            Toast("上传失败，请重新上传");
          }
        });
      } else {
        param.append("file", file);
        let res = await this.$Api.uploadPicture(
          this.checkedResult[0].phone,
          param
        );
        if (res.data.cnt == 1) {
          Toast.success("上传回执单成功");
        } else {
          Toast("上传失败，请重新上传");
        }
      }
    },
    //浏览器兼容
    getObjectURL(file) {
      let url = null;
      if (window.createObjectURL !== undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    //结束订单
    async CloseOrder() {
      if (this.checkedResult.length <= 0) {
        Toast("请先选择订单");
      } else {
        let arr = [];
        this.checkedResult.map((item) => {
          arr.push(item.waybillNo);
        });
        let strArr = arr.join(",");
        let params = {
          waybillNo: strArr,
        };
        let res = await this.$Api.endWaybillByWaybillNo(params);
        if (res.data == true) {
          this.showPopwaybill = false;
          Toast.success("结束订单成功");
          this.query.page = 0;
          this.dataList = [];
          this.getList();
        } else {
          Toast("结束订单失败");
        }
      }
    },
    //获取列表数据
    async getList(type) {
      let orderNo = null;
      //如果有type则是筛选框搜索，把page和页面数据列表重置
      if (type || type == 0) {
        this.query.page = 0;
        this.dataList = [];
        this.scorllLoading = false;
      } else if (
        this.screenForm.driverName != "" ||
        this.screenForm.orderNo != "" ||
        this.screenForm.receiveOrderNo != "" ||
        this.screenForm.transportNo != "" ||
        this.screenForm.createTimeStart != "" ||
        this.screenForm.createTimeEnd != ""
      ) {
        //如果筛选框中有不为空的，则是筛选框搜索，重置请求page和数据列表
        this.query.page = 0;
        this.dataList = [];
      }
      this.query.page++;
      //入参
      let params = {
        userId: this.$store.state.userInfo.deptId,
        page: this.query.page,
        size: this.query.size,
        driverName: this.screenForm.driverName, //司机姓名
        orderNo: this.screenForm.orderNo, //订单号
        receiveOrderNo: this.screenForm.receiveOrderNo, //原始订单号
        transportNo: this.screenForm.transportNo, //计划号
        createTimeStart: this.screenForm.createTimeStart,
        createTimeEnd: this.screenForm.createTimeEnd,
      };
      let res = await this.$Api.transport(params);
      if (res.status != 200) return false;
      let arr = [];
      let planArr = [];
      //如果当前请求页码大于等于总页码，则请求加载没有更多数据，不再触发下拉加载更多事件
      if (this.query.page >= res.data.totalPage) {
        this.scorllLoading = true;
      }
      //点击触发展开更多
      res.data.list.map((item) => {
        if (item.orderInfo.length > 3) {
          item.orderInfoStatu = false;
        } else if (item.planOrderInfo.length > 3) {
          item.planPrderInfoStatu = false;
        }
        arr.push(item);
      });
      this.dataList = this.dataList.concat(arr);
    },
    //点击显示隐藏更多
    showList(item) {
      // console.log(item);
      //超过3条则隐藏
      if (item.orderInfoStatu == false) {
        var showList = [];
        if (item.orderInfo.length > 3) {
          for (let i = 0; i < 3; i++) {
            showList.push(item.orderInfo[i]);
          }
        } else {
          showList = item.orderInfo;
        }
        return showList;
      } else {
        return item.orderInfo;
      }
    },
    //点击显示隐藏更多Plan
    showListPlan(item) {
      // console.log(item);
      if (item.planPrderInfoStatu == false) {
        let showList = [];
        if (item.planOrderInfo.length > 3) {
          for (let i = 0; i < 3; i++) {
            showList.push(item.planOrderInfo[i]);
          }
        } else {
          showList = item.planOrderInfo;
        }
        return showList;
      } else {
        return item.planOrderInfo;
      }
    },
    //点击mark折叠展开事件
    ChangeOrderInfoStatu(status, item) {
      item.orderInfoStatu = status;
      this.showList(item);
    },
    ChangeOrderInfoJhStatu(status, item) {
      item.planPrderInfoStatu = status;
      this.showList(item);
    },
    //隐藏更多
    HideMore(item) {
      this.showAll = !this.showAll;
    },
    //跳转到运单详情修改运单
    goWaybillDetails(value) {
      window.sessionStorage.setItem("orderNo", value);
      this.$router.push({
        path: "/waybillDetails",
        query: {
          orderNo: window.sessionStorage.getItem("orderNo"),
        },
      });
    },
  },
};
</script>
<style lang="scss"  scoped>
.main {
  height: 84vh;
  overflow-y: auto;
  font-size: 0.37rem;
  .deriver_main {
    width: 9.14rem;
    margin: 0.27rem 0 0.23rem 0.43rem;
    border-bottom: 4px solid #f5f5f5;
    padding-bottom: 0.34rem;
  }
  .driverInfo {
    .driverInfo_detail {
      position: relative;
      .driver_name {
        height: 0.64rem;
        font-size: 0.43rem;
        background-image: url("../../assets/img/icon/waybill_user2x.png");
        background-repeat: no-repeat;
        background-size: 0.64rem;
        padding-left: 0.64rem;
        line-height: 0.69rem;
        span {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.32rem;
          width: 1.15rem;
          height: 0.45rem;
          line-height: 0.45rem;
          text-align: center;
          border-radius: 0.1rem;
        }
        .useYd {
          padding: 0;
          margin-top: 0.1rem;
          color: #fb6600;
          background-color: rgba($color: #fb6600, $alpha: 0.2);
        }
        .noYd {
          padding: 0;
          color: #999999;
          background-color: rgba($color: #999999, $alpha: 0.2);
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        padding: 0.16rem 0;
      }
      span:nth-child(2) {
        text-align: right;
      }
    }
  }
  .orderBox {
    // margin-bottom: 2.33rem;
    padding-bottom: 2.33rem;
    max-height: 12rem;
    overflow: scroll;
  }
  .order {
    padding: 0.13rem 0 0.3rem 0.38rem;
    border-bottom: 5px solid #f5f5f5;
  }
  .orderInfo {
    position: relative;
    overflow: visible;
    .mark_ddd {
      z-index: 999;
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.64rem;
      height: 0.64rem;
      background-repeat: no-repeat;
      background-size: 0.64rem;
      background-image: url("../../assets/img/icon/waybill_ddd.png");
    }
    .mark_zk {
      z-index: 999;
      position: absolute;
      width: 0.42rem;
      height: 0.42rem;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      background-repeat: no-repeat;
      background-size: 0.42rem;
      background-image: url("../../assets/img/icon/zk_zd.png");
    }
  }
  .orderList {
    h3 {
      font-size: 0.32rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      flex: 1;
      font-size: 0.32rem;
      // height: 0.45rem;
      height: 0.45rem;
      line-height: 0.45rem;
      &:nth-child(1) {
        flex: 1;
        padding: 0.16rem 0 0 0.64rem;
        background-image: url("../../assets/img/icon/waybill_order2x.png");
        background-repeat: no-repeat;
        background-size: 0.64rem;
      }
      &:nth-child(2) {
        width: 3.1rem;
        padding: 0 0 0.15rem 0.7rem;
      }
    }
    .orderList_time {
      .van-image {
        width: 0.64rem;
        height: 0.64rem;
        vertical-align: middle;
        padding-bottom: 0.1rem;
      }
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .orderList1 {
    h3 {
      font-size: 0.32rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      flex: 1;
      font-size: 0.32rem;
      // height: 0.45rem;
      height: 0.45rem;
      line-height: 0.45rem;
      &:nth-child(1) {
        flex: 1;
        padding: 0.16rem 0 0 0.64rem;
        background-image: url("../../assets/img/icon/waybill_order2x1.png");
        background-repeat: no-repeat;
        background-size: 0.64rem;
      }
      &:nth-child(2) {
        width: 3.1rem;
        padding: 0 0 0.15rem 0.7rem;
      }
    }
    .orderList_time {
      .van-image {
        width: 0.64rem;
        height: 0.64rem;
        vertical-align: middle;
        padding-bottom: 0.1rem;
      }
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.order {
  font-size: 0.37rem;
  .order_container {
    // background-color: pink;
    background-color: #ffffff;
    // height: 3.28rem;
    border-radius: 0.3rem;
  }
  .order_container_jh {
    // background-color: pink;
    // height: 4.03rem;
    background-color: #ffffff;
    // height: 3.28rem;
    border-radius: 0.3rem;
  }
  .order_container_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      padding: 5px 0.27rem;
      color: #999999;
    }
    span:nth-child(2) {
      padding-right: 0.61rem;
    }
  }
  .order_userinfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .order_userinfo_check {
      width: 1.04rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .checked {
        width: 0.5rem;
        height: 0.5rem;
        /deep/.van-checkbox__icon .van-icon {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
    .order_userinfo_rigth {
      flex: 1;
      // height: 100%;
      padding-right: 0.3rem;

      .order_userinfo_rigth1 {
        padding-top: 0.21rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // height: 0.53rem;
        span {
          color: #999999;
        }
        .order_userinfo_rigth_item {
          display: flex;
          justify-content: left;
          align-items: center;
          span {
            color: #000000;
            line-height: 0.64rem;
            padding-left: 0.6rem;
            background: url("../../assets/img/icon/order_user2x.png") no-repeat;
            background-size: 0.64rem;
          }
          .tel_icon {
            background: url("../../assets/img/icon/order_tel2x.png") no-repeat;
            background-size: 0.64rem;
          }
        }
      }
      .order_params {
        margin-top: 0.21rem;
      }
    }
  }
}
.order_userinfo_rigth_adress {
  background: url("../../assets/img/icon/order_adress2x.png") no-repeat;
  background-size: 0.64rem;
  padding-top: 0.2rem;
  position: relative;
  margin-bottom: -0.3rem;
  .adress_text {
    padding-left: 0.6rem;
    line-height: 0.6rem;
    margin-top: -0.2rem;
    // line-height: 0.3rem;
  }
}
.order_tile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999999;
  .order_title_img1 {
    width: 0.64rem;
    height: 0.64rem;
  }
  .order_title_img2 {
    width: 1.55rem;
    height: 0.48rem;
    padding-left: 2.12rem;
  }
  .order_no {
    color: #999999;
    width: 5.44rem;
    height: 0.53rem;
    font-size: 0.37rem;
    line-height: 0.53rem;
    margin-left: -0.5rem;
  }
  .order_time {
    padding-right: 0.28rem;
  }
}
.detail_title {
  padding: 0.4rem 0 0.2rem 0;
  height: 0.59rem;
  line-height: 0.59rem;
  text-align: center;
  font-size: 0.43rem;
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0.1rem 0.1rem 0.1rem #cccccc;
  background: #ffffff;
  height: 1.33rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  div {
    width: 3.73rem;
    height: 0.85rem;
    color: #ffffff;
    line-height: 0.85rem;
    text-align: center;

    border-radius: 0.85rem;
  }
  .btn_qx {
    background: #0094ff;
  }
  .btn_sub {
    background: #fb6600;
  }
}
// screen弹出框
.screen_title {
  font-size: 0.43rem;
  text-align: center;
  line-height: 0.59rem;
  padding-top: 0.27rem;
}
h4 {
  font-size: 0.37rem;
  font-weight: 400;
  line-height: 0.53rem;
  padding: 0.27rem 0 0.17rem 0.27rem;
  display: flex;
  span {
    color: #40acf8;
    font-size: 0.27rem;
    flex: 1;
    text-align: right;
  }
}
h3 {
  font-size: 0.37rem;
}
.screen_type {
  height: 0.8rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .screen_type_item {
    margin: 0.3rem;
    width: 2.35rem;
    height: 100%;
    background: #f5f5f5;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    color: #999999;
  }
  .screen_type_check {
    color: #fb6600;
    background: rgba($color: #fb6600, $alpha: 0.1);
  }
}
.screen_orderNo,
.screen_ys_orderNo,
.screen_ys_orderNo {
  width: 9.15rem;
  height: 0.8rem;
  border: #fb6600;
  margin: 0 auto;
}
.van-cell {
  background: #f5f5f5;
  border-radius: 0.8rem;
  padding: 0.15rem 0.4rem;
}
.screen_sub {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.45rem;
  div {
    height: 100%;
    width: 2.13rem;
    border-radius: 0.85rem;
    text-align: center;
    color: #ffffff;
    font-size: 0.43rem;
    line-height: 0.85rem;
    background: #fb6600;
  }
}
.screen_time {
  width: 9.15rem;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 4.21rem;
    /deep/.el-input__inner {
      border-radius: 0.8rem;
      height: 0.8rem;
      background: #f5f5f5;
      border: 0;
    }
    /deep/.el-icon-date {
      opacity: 1;
    }
    /deep/.el-icon-circle-close {
      line-height: 0.8rem;
    }
  }
}
/deep/.van-popup {
  max-height: 75%;
  overflow: hidden;
}

.orderNo {
  padding-top: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .orderNo_no {
    font-size: 0.37rem;
    color: #999999;
    flex: 1;
  }
  .orderNo_img {
    margin-left: 0.43rem;
    width: 0.64rem;
    height: 0.64rem;
    img {
      height: 100%;
    }
  }
}
.order_main {
  border-radius: 0.3rem;
  background: #ffffff;
  font-size: 0.37rem;
  padding-bottom: 0.15rem;
  .order_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      flex: 1;
      padding: 0.2rem 0.43rem;
    }
    span:nth-child(2) {
      text-align: right;
      color: #1aa3ff;
    }
  }
}
.order_info {
  padding-left: 0.75rem;
  .order_info_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      padding: 0.06rem 0;
    }
    span:nth-child(2) {
      width: 3.5rem !important;
    }
  }
}
.fenge {
  height: 0.1rem;
  background: #f5f5f5;
  margin-bottom: 0.1rem;
}
.fenge1 {
  height: 0.03rem;
  background: #f5f5f5;
  margin-bottom: 0.1rem;
}
.noLoading {
  text-align: center;
  height: 0.5rem;
  font-size: 0.37rem;
  color: #999999;
}
</style>